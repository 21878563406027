import { useEffect, useState } from 'react';
import { ClaimRequest } from '@barkibu/noma-commons';
import { InvoiceNomaRepository } from '../infraestructure/repositories/invoice/InvoiceNomaRepository';

const invoiceRepository = new InvoiceNomaRepository();

export const useInvoiceRelatedClaimRequests = (
    taxIdNumber: string | undefined,
    number: string | undefined,
    date: Date | null | undefined
) => {
    const [claimRequests, setClaimRequests] = useState<ClaimRequest[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setClaimRequests([]);

        if (taxIdNumber == null || number == null || !date) {
            return;
        }

        setLoading(true);

        invoiceRepository
            .findInvoiceRelatedClaimRequests(taxIdNumber, number, date)
            .then(setClaimRequests)
            .catch(() => {
                console.error('Error while fetching invoice related claim requests');
                setClaimRequests([]);
            })
            .finally(() => setLoading(false));
    }, [taxIdNumber, number, date]);

    return { claimRequests, loading };
};
