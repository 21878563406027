import { useModuleTranslation } from '../../utils/useModuleTranslation';
import ReactJson from 'react-json-view';
import './timeline.modules.scss';

export type TimelineProps<T = string> = {
    items: { name: T; timestamp: Date; subtitle?: T; payload?: T; action?: () => void }[];
    sortDesc?: boolean;
};
const Timeline = ({ items, sortDesc = true }: TimelineProps) => {
    const { t } = useModuleTranslation();

    const sortedItems = sortDesc
        ? items.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
        : items.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

    const mapDateTime = (date: Date) => {
        const now = new Date();
        const timeDifference = now.getTime() - date.getTime();

        const minutes = Math.floor(timeDifference / (1000 * 60));
        if (minutes < 59) {
            return `${minutes} ${t('timeline.minutes_ago')}`;
        }

        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        if (hours < 24) {
            return `${hours} ${t('timeline.hours_ago')}`;
        }

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        if (days < 7) {
            return `${days} ${t('timeline.days_ago')}`;
        }

        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    const formattedPayload = (payload: string) => {
        const obj = JSON.parse(payload);

        return (
            <ul>
                {Object.entries(obj)
                    .filter(([it]) => !it.toLocaleLowerCase().includes('key'))
                    .map(([key, value]) => (
                        <li key={key}>
                            {key}: {JSON.stringify(value)}
                        </li>
                    ))}
            </ul>
        );
    };
    return (
        <ul className="timeline">
            {sortedItems.map((item, index) => (
                <li className="timeline-item" key={index}>
                    {index !== sortedItems.length && <div className="timeline-dot"></div>}
                    <div className="timeline-content">
                        <p title={item.timestamp.toLocaleTimeString()}>{mapDateTime(item.timestamp)}</p>
                        <p>{item.name}</p>
                        {item.subtitle && <p>{item.subtitle}</p>}
                        {item.payload && (
                            <ReactJson src={JSON.parse(item.payload)} collapsed={2} displayDataTypes={false} />
                        )}
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default Timeline;
