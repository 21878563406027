import { useEffect, useState } from 'react';
import {
    Button,
    ButtonVariant,
    Chip,
    ChipType,
    DatePicker,
    DirectionLeftIcon,
    DirectionUpIcon,
    Input,
    ProgressIndicator,
    RelatedClaimRequest,
} from '@barkibu/noma-commons';
import { useInvoiceRelatedClaimRequests } from '../../../../../application/useInvoiceRelatedClaimRequests';
import Invoice from '../../../../../domain/entities/Invoice';
import { useModuleTranslation } from '../../../../../utils/useModuleTranslation';
import './invoice-header.scss';

export interface InvoiceHeaderProps {
    selectedInvoiceIndex: number;
    invoiceCollectionLength: number;
    handlePreviousInvoiceClick: () => void;
    handleNextInvoiceClick: () => void;
    readOnly: boolean;
    invoice: Invoice;
    onUpdateDate: (key: string, date: Date) => void;
    onUpdateNumber: (key: string, number: string) => void;
    onUpdateTaxIdNumber: (key: string, taxIdNumber: string) => void;
    claimRequestKey?: string;
}

const InvoiceHeader = ({
    selectedInvoiceIndex,
    invoiceCollectionLength,
    handlePreviousInvoiceClick,
    handleNextInvoiceClick,
    readOnly,
    invoice,
    onUpdateDate,
    onUpdateNumber,
    onUpdateTaxIdNumber,
    claimRequestKey,
}: InvoiceHeaderProps) => {
    const { t } = useModuleTranslation();
    const [dateSelected, setDateSelected] = useState<Date | null | undefined>(invoice.date);
    const [numberSelected, setNumberSelected] = useState(invoice.number);
    const [taxIdNumberSelected, setTaxIdNumberSelected] = useState(invoice.taxIdNumber);

    const { claimRequests, loading: relatedClaimRequestLoading } = useInvoiceRelatedClaimRequests(
        taxIdNumberSelected,
        numberSelected,
        dateSelected
    );

    useEffect(() => {
        setDateSelected(invoice.date);
        setNumberSelected(invoice.number);
        setTaxIdNumberSelected(invoice.taxIdNumber);
    }, [invoice]);

    const handleDateChange = (date: Date | null) => {
        setDateSelected(date);
        if (date != null) {
            onUpdateDate(invoice.key!, date);
        }
    };

    const handleNumberChange = (number: string) => {
        setNumberSelected(number);
    };

    const handleNumberTouched = () => {
        if (numberSelected != null) {
            onUpdateNumber(invoice.key!, numberSelected);
        }
    };

    const handleTaxIdNumberChange = (taxIdNumber: string) => {
        setTaxIdNumberSelected(taxIdNumber);
    };

    const handleTaxIdNumberTouched = () => {
        if (taxIdNumberSelected != null) {
            onUpdateTaxIdNumber(invoice.key!, taxIdNumberSelected);
        }
    };

    const claimsWithInvoiceDuplicated = claimRequests.filter((claimRequest) => claimRequest.key != claimRequestKey);

    return (
        <>
            <div className="invoice-header">
                <div className="invoice-header--carrousel">
                    <Button
                        icon={DirectionLeftIcon}
                        variantName={ButtonVariant.BASIC_INFO}
                        onClick={() => handlePreviousInvoiceClick()}
                        disabled={selectedInvoiceIndex == 0}
                        accessibilityText={t('invoice.go_to_previous')}
                    />
                    <h2 className={'title'}>
                        {t('invoice.title', {
                            current: selectedInvoiceIndex + 1,
                            totalCount: invoiceCollectionLength,
                        })}
                    </h2>
                    <Button
                        icon={DirectionUpIcon}
                        variantName={ButtonVariant.BASIC_INFO}
                        onClick={() => handleNextInvoiceClick()}
                        disabled={selectedInvoiceIndex >= invoiceCollectionLength - 1}
                        accessibilityText={t('invoice.go_to_next')}
                    />
                    {relatedClaimRequestLoading && <ProgressIndicator />}
                    {claimsWithInvoiceDuplicated.length > 0 && (
                        <RelatedClaimRequest
                            title={t('invoice.duplicated.related_claims')}
                            claimRequestsRelated={claimsWithInvoiceDuplicated}
                            showUsages={false}
                        >
                            <Chip
                                type={ChipType.ACTIONABLE}
                                text={t('invoice.duplicated.description')}
                                icon={DirectionUpIcon}
                                onClick={() => null}
                                color={'orange'}
                            />
                        </RelatedClaimRequest>
                    )}
                </div>
                <div className="invoice-header--actions">
                    <DatePicker
                        value={dateSelected}
                        label={t('invoice.date') || undefined}
                        onChange={(date) => handleDateChange(date)}
                        disabled={readOnly}
                    />
                    <Input
                        name="number"
                        value={numberSelected ?? ''}
                        onChange={handleNumberChange}
                        setTouched={handleNumberTouched}
                        label={t('invoice.number')}
                        disabled={readOnly}
                    ></Input>
                    <Input
                        name="taxIdNumber"
                        value={taxIdNumberSelected ?? ''}
                        onChange={handleTaxIdNumberChange}
                        setTouched={handleTaxIdNumberTouched}
                        label={t('invoice.cif')}
                        disabled={readOnly}
                    ></Input>
                </div>
            </div>
        </>
    );
};

export default InvoiceHeader;
